import { EnergyMetersState, TransactionType, WaterMeterState } from '@core/enums';
import { TransactionUser } from '../interfaces/transaction-user.interface';
import { Address, Asset, DigitalMeterReadings } from '@core/interfaces';
import { EnergyType } from '@shared/move/enums/energy-type.enum';
import { ElectricityMeterType } from '@smooved-lib/lib/meters/enums/electricity-meter-type';
import { TransactionResponse } from '../types/transaction-response';

export class Transaction {
    id: string;
    transactionType: TransactionType;
    transferee?: TransactionUser;
    leaver?: TransactionUser;
    address: Address;
    movingDate: Date;
    energyMeters: {
        energyMetersState: EnergyMetersState;
        attachments: Asset[];
        energyType?: EnergyType;
        electricity?: {
            meterType: ElectricityMeterType;
            solarPanels: boolean;
            eanCode: string;
            singleMeterReading: string;
            doubleDayMeterReading: string;
            doubleNightMeterReading: string;
            exclusiveNight: boolean;
            exclusiveNightMeterReading: string;
            energyDigitalMeterReadings?: DigitalMeterReadings;
        };
        gas?: {
            eanCode: string;
            meterReading: string;
        };
    };
    waterMeter?: {
        waterMeterState?: WaterMeterState;
        attachments?: Asset[];
        waterSupplier?: string;
    };

    constructor(partial: Partial<TransactionResponse>) {
        this.id = partial.id;
        this.transactionType = partial.transactionType;
        this.transferee = partial.transferee;
        this.leaver = partial.leaver;
        this.address = partial.address;
        this.movingDate = partial.movingDate;
        this.energyMeters = partial.energyMeters;
        this.waterMeter = partial.waterMeter;
    }

    public getTransactionUser(): TransactionUser {
        return this.transferee || this.leaver;
    }
}
